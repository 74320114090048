<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <div class="complaint-content">
            <div class="complaint-title a-fs-16 a-c-master a-fw-700">投诉进展</div>
            <div class="step-box">
                <el-steps :active="stepAc" align-center>
                    <el-step>
                        <img src="../../assets/icon/wait-icon.png" slot="icon" style="width:15px;height:15px" alt="" v-if="stepAc==0">
                        <div class="step-spot1" slot="icon" v-else-if="stepAc>0"></div>
                        <div class="step-spot" slot="icon" v-else></div>
                        <span slot="title" class="a-fs-14">{{ complaintInfo.userComplaintTimes > 1 ? '用户再次投诉' : '用户投诉' }}</span>
                    </el-step>
                    <el-step title="步骤 2" >
                        <img src="../../assets/icon/wait-icon.png" slot="icon" style="width:15px;height:15px" alt="" v-if="stepAc==1">
                        <div class="step-spot1" slot="icon" v-else-if="stepAc>1"></div>
                        <div class="step-spot" slot="icon" v-else></div>
                        <span slot="title" class="a-fs-14">待处理</span>
                    </el-step>
                    <el-step title="步骤 3">
                        <img src="../../assets/icon/wait-icon.png" slot="icon" style="width:15px;height:15px" alt="" v-if="stepAc==2">
                        <div class="step-spot1" slot="icon" v-else-if="stepAc>2"></div>
                        <div class="step-spot" slot="icon" v-else></div>
                        <span slot="title" class="a-fs-14">处理中</span>
                    </el-step>
                    <el-step title="步骤 3" >
                        <img src="../../assets/icon/wait-icon.png" slot="icon" style="width:15px;height:15px" alt="" v-if="stepAc==3">
                        <div class="step-spot1" slot="icon" v-else-if="stepAc>3"></div>
                        <div class="step-spot" slot="icon" v-else></div>
                        <span slot="title" class="a-fs-14">已处理完成</span>
                    </el-step>
                </el-steps>
            </div>
            <div class="complaint-title a-fs-16 a-c-master a-fw-700 a-mt-24">投诉详情</div>
            <div class="a-flex-rfsfs a-flex-wrap" v-if="complaintInfo">
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">投诉单号</span>
                    <span class="content a-c-master">{{ complaintInfo.complaintId || '-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">投诉时间</span>
                    <span class="content a-c-master">{{ $getDay.getTimeStampToStr(complaintInfo.complaintTime) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">投诉类型</span>
                    <span class="content a-c-master">{{ complaintInfo.problemType | initDic(problemTypeDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">问题描述</span>
                    <span class="content a-c-master">{{ complaintInfo.problemDescription || '-' }}</span>
                </div>
                <!-- <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">投诉人昵称</span>
                    <span class="content a-c-master">{{ '-' }}</span>
                </div> -->
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">风险状态</span>
                    <div class="content a-c-master" v-for="(item, index) in complaintInfo.user_tag_list" :key="index">
                        <span>{{ item | initDic(userTagDic) }}</span>
                    </div>
                    <span class="content a-c-master" v-if="!complaintInfo.user_tag_list">正常</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">申请退款金额</span>
                    <span class="content a-c-master">￥{{ util.numFormat(complaintInfo.applyRefundAmount) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">订单金额</span>
                    <span class="content a-c-master" v-for="(item, index) in complaintInfo.complaintOrderInfo" :key="index">
                        ￥{{ util.numFormat(item.amount) }}
                    </span>
                    <span class="content a-c-master" v-if="!complaintInfo.complaintOrderInfo">-</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">被诉商户号</span>
                    <span class="content a-c-master">{{ complaintInfo.complaintedMchid || '-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">被诉商户名</span>
                    <span class="content a-c-master">{{ complaintInfo.companyName || '-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">微信支付订单号</span>
                    <span class="content a-c-master" v-for="(item, index) in complaintInfo.complaintOrderInfo" :key="index">
                        {{ item.transactionId || '-' }}
                    </span>
                    <span class="content a-c-master" v-if="!complaintInfo.complaintOrderInfo">-</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">投诉人联系方式</span>
                    <span class="content a-c-master">{{ complaintInfo.payerPhone || '-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">商户订单号</span>
                    <span class="content a-c-master" v-for="(item, index) in complaintInfo.complaintOrderInfo" :key="index">
                        {{ item.outTradeNo || '-' }}
                    </span>
                    <span class="content a-c-master" v-if="!complaintInfo.complaintOrderInfo">-</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">投诉人openid</span>
                    <span class="content a-c-master">{{ complaintInfo.payerOpenid || '-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付分服务单号</span>
                    <span class="content a-c-master" v-for="(item, index) in complaintInfo.serviceOrderInfo" :key="index">
                        {{ item.orderId || '-' }}
                    </span>
                    <span class="content a-c-master" v-if="!complaintInfo.serviceOrderInfo || !complaintInfo.serviceOrderInfo.length">-</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">用户投诉次数</span>
                    <span class="content a-c-master">{{ complaintInfo.userComplaintTimes || 0 }}</span>
                </div>
            </div>
            <div class="complaint-title a-fs-16 a-c-master a-fw-700 a-mt-24">协商历史</div>
            <le-pagview 
                ref="complaintHistoryListPage" 
                @setData="setTableData" 
                :pageParam="pageParam" 
                :isFixed="false" 
                :tableRef="$refs.complaintHistoryList" 
                :pageSizeArr='[300]'
                :pageSizeDefault='300'>
                <el-table 
                    ref="complaintHistoryList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    style="width: 100%">
                    <el-table-column prop="nameId" label="日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.operate_time || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargeFeeTypeId" label="角色" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.operator || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargePayeeTypeId" label="动作类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.operate_type | initDic(operateTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="propertyListId" label="内容" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.operate_details || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unitPriceId" label="图片" min-width="120">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsfs a-flex-wrap a-w-100" v-if="row.complaint_media_list && row.complaint_media_list.media_url">
                                <wx-image 
                                    style="width:100px;height:100px;margin:5px"
                                    v-for="(item,index) in row.complaint_media_list.media_url" 
                                    :key="index" 
                                    :imageUrl="item"
                                    :extraParams="{
                                        'mchId': mchId
                                    }">
                                </wx-image>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
            <div style="height:100px"></div>
        </div>
        <div class="a-line-t-e0 footerBox">
            <el-button 
                type="danger" 
                class="a-ml-24 a-mt-15" 
                @click="handleReject" 
                v-if="complaintInfo.complaintState != 'PROCESSED' && complaintInfo.problemType == 'REFUND' && complaintInfo.userComplaintTimes == 1">驳回</el-button>
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="handleRefound" v-if="!complaintInfo.complaintFullRefunded">发起退款</el-button>
            <el-button class="a-ml-24 a-mt-15 " style="margin-left:12px;" @click="handleApply" >回复用户</el-button>
            <el-button class="a-ml-24 a-mt-15 " style="margin-left:12px;" @click="handleComplete" v-if="complaintInfo.canComplete && complaintInfo.complaintState != 'PROCESSED'">处理完成</el-button>
        </div>
        <complaint-apply ref="complaintApply" @handlerSuccess='handlerApplySuccess'></complaint-apply>
        <complaint-reject ref="complaintReject" @handlerSuccess='handlerRejectSuccess'></complaint-reject>
        <cabinet-order-refund ref="cabinetOrderRefund" @oprateFinish="refundFinish"></cabinet-order-refund>
        <package-order-refound ref="packageOrderRefound" @oprateFinish="refundFinish"></package-order-refound>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    import complaintApply from "./child/complaint-apply.vue";
    import complaintReject from "./child/complaint-reject.vue";
    import CabinetOrderRefund from '../order/child/cabinetOrderRefund.vue'
    import PackageOrderRefound from '../order/child/packageOrderRefound.vue'
    import WxImage from '../components/formcy/wx-image.vue';
    export default {
        components: {
            CabinetOrderRefund,
            PackageOrderRefound,
            complaintApply,
            complaintReject,
            WxImage
        },
        data () {
            return {
                util: util,
                stepAc: 0,
                id: '',
                mchId: '',
                complaintInfo: '',
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.complaintHistorys,
                    method: "post",
                    params: {
                        complaintId: this.$route.query.id,
                        mchId: this.$route.query.mchId
                    },
                    freshCtrl: 1,
                },
                operateTypeDic: [],
                userTagDic: [],
                problemTypeDic: [],
                launchRefundDay: 3,//发起退款预计时间（天）
            }
        },
        mounted () {
            this.$getDic('historyOperateType','select').then(res=>{ this.operateTypeDic = res; })
            this.$getDic('userTag','select').then(res=>{ this.userTagDic = res; })
            this.$getDic('complaintProblemType','select').then(res=>{ this.problemTypeDic = res; })
            this.id = this.$route.query.id
            this.mchId = this.$route.query.mchId
            this.getComplaintInfo()
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
            getComplaintInfo () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.complaintDetail,
                    method: "post",
                    params: {
                        complaintId: this.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        try {
                            let datas = JSON.parse(res.result.data.details)
                            this.complaintInfo = {
                                ...datas,
                                orderType: datas.complaintOrderInfo[0].orderType,
                                orderSn: datas.complaintOrderInfo[0].orderSn,
                                complaintState: res.result.data.complaintState,
                                userComplaintTimes: res.result.data.userComplaintTimes,
                                companyName: res.result.data.companyName,
                                canComplete: res.result.data.canComplete,
                            }

                            if(this.complaintInfo.complaintState == 'PENDING') {
                                this.stepAc = 1
                            }else if(this.complaintInfo.complaintState == 'PROCESSING') {
                                this.stepAc = 2
                            }else if(this.complaintInfo.complaintState == 'PROCESSED') {
                                this.stepAc = 3
                            }
                        } catch (error) {
                            this.$message.error(error)
                        }
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            handleApply () {
                let datas = this.complaintInfo
                this.$refs['complaintApply'].form.complainted_mchid = datas.complaintedMchid
                this.$refs['complaintApply'].form.mchId = this.mchId
                this.$refs['complaintApply'].form.response_content = ''
                this.$refs['complaintApply'].form.response_images = []
                this.$refs['complaintApply'].form.response_medias = []
                this.$refs['complaintApply'].form.complaintId = datas.complaintId
                this.$refs['complaintApply'].dialogVisible = true
            },
            handleReject () {
                let datas = this.complaintInfo
                this.$refs['complaintReject'].form.action = 'REJECT'
                this.$refs['complaintReject'].form.mchId = this.mchId
                this.$refs['complaintReject'].form.reject_reason = ''
                this.$refs['complaintReject'].form.reject_media_list = []
                this.$refs['complaintReject'].form.reject_img_list = []
                this.$refs['complaintReject'].form.complaintId = datas.complaintId
                this.$refs['complaintReject'].form.remark = ''
                this.$refs['complaintReject'].dialogVisible = true
            },
            handleComplete () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.complaintComplete,
                    method: "post",
                    params: {
                        complaintId: this.id,
						complaintMchId: this.complaintInfo.complaintedMchid,
						mchId: this.mchId
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.getComplaintInfo()
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            async handleRefound () {
                let datas
                let row = this.complaintInfo
                if(row.canRefund) {
                    await this.handleUpdateRefound(row)
                }
                switch (row.orderType) {
                    case 1:
                        // 套餐订单
                        datas = await this.getPackageOrderInfo(row)
                        if(datas) {
                            this.$refs['packageOrderRefound'].id = datas.id
                            this.$refs['packageOrderRefound'].form.refundAmount = Number(((datas.payAmount - datas.refundAmount)/100).toFixed(2))
                            this.$refs['packageOrderRefound'].form.returnGoods = 1
                            this.$refs['packageOrderRefound'].form.payAmount = datas.payAmount
                            this.$refs['packageOrderRefound'].orderSn = datas.orderSn
                            try {
                                this.$refs['packageOrderRefound'].form.packageType = JSON.parse(datas.goodsInfo).type
                                this.$refs['packageOrderRefound'].form.packgeSoybean = JSON.parse(datas.goodsInfo).promotionPrice || 0
                                this.$refs['packageOrderRefound'].form.packgeSoybeanGift = JSON.parse(datas.goodsInfo).giftAmount || 0
                            } catch (error) {
                                
                            }
                            this.$refs['packageOrderRefound'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6 //退款时将vip优惠剔除出去
                            }).map(item=>{
                                return {
                                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    discountType: item.discountType
                                }
                            })
                            this.$refs['packageOrderRefound'].dialogVisible = true
                        }
                        break;
                    case 2:
                        // 充电桩订单
                        datas = await this.getOrderInfoById(row)
                        if(datas) {
                            this.$refs['cabinetOrderRefund'].id = datas.orderId //订单id
                            this.$refs['cabinetOrderRefund'].orderType = datas.orderType //订单类型
                            this.$refs['cabinetOrderRefund'].originRefundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6//退款时将vip优惠剔除出去
                            }).map(item=>{
                                return {
                                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    discountType: item.discountType
                                }
                            })
                            if(datas.superOrderItem) {
                                this.$refs['cabinetOrderRefund'].superOrderItem = datas.superOrderItem.find(res=>{
                                    return res.goodsType == 2
                                }) //安心充订单
                            }
                            this.$refs['cabinetOrderRefund'].dialogVisible = true
                        }
                        break;
                    case 3:
                        // 水机订单
                        this.$confirm(`是否确认为订单：${row.orderSn}退款？`,'温馨提示')
                        .then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.waterOrderRefund + '/' + row.orderSn,
                                method: "post",
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.getComplaintInfo()
                                    this.pageParam.freshCtrl++;
                                }
                            })
                            .catch(err=>{ })
                        })
                        .catch(_=>{})
                        break;
                    case 4:
                    case 5:
                        // 充电柜、充电舱订单
                        datas = await this.getCabinetOrderInfo(row)
                        if(datas) {
                            this.$refs['cabinetOrderRefund'].id = datas.id //订单id
                            this.$refs['cabinetOrderRefund'].orderType = datas.orderType //订单类型
                            this.$refs['cabinetOrderRefund'].originRefundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
                            this.$refs['cabinetOrderRefund'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6//退款时将vip优惠剔除出去
                            }).map(item=>{
                                return {
                                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                                    discountType: item.discountType
                                }
                            })
                            if(datas.superOrderItem) {
                                this.$refs['cabinetOrderRefund'].superOrderItem = datas.superOrderItem.find(res=>{
                                    return res.goodsType == 2
                                }) //安心充订单
                            }
                            this.$refs['cabinetOrderRefund'].dialogVisible = true
                        }
                        break;
                    default:
                        break;
                }
            },
            refundFinish () {
                this.getComplaintInfo()
                this.pageParam.freshCtrl++;
            },
            handlerApplySuccess () {
                this.pageParam.freshCtrl++;
            },
            handlerRejectSuccess () {
                this.pageParam.freshCtrl++;
            },
            // 更新退款状态
            handleUpdateRefound (datas) {
                return new Promise((resolve, reject)=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.complaintUpdateRefund,
                        params: {
                            action: 'APPROVE',//操作类型
                            mchId: this.mchId,
                            complaintId: datas.complaintId,
                            launchRefundDay: this.launchRefundDay
                        },
                    })
                    .then(res => {
                        if(res.result.code == 0){
                            resolve()
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.result.message)
                        reject()
                    });
                })
            },
            // 获取套餐订单详情
            getPackageOrderInfo (datas) {
                return new Promise((resolve, reject) => {
                    this.$Axios._get({
                        url: this.$Config.apiUrl.superOrderDetails,
                        method: "get",
                        params: {
                            id: datas.orderSn
                        }
                    }).then(res => {
                        if(res.result.code == 0){
                            resolve(res.result.data)
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    }).catch(err=>{
                        this.$message.error(err.result.message)
                        reject()
                    })
                })
            },
            // 查看充电柜订单详情
            getCabinetOrderInfo (datas) {
                return new Promise((resolve, reject) => {
                    this.$Axios._get({
                        url: this.$Config.apiUrl.cabinetOrderInfo,
                        params: {
                            boxOrderId: datas.orderSn
                        },
                    }).then((res) => {
                        if(res.result.code == 0){
                            resolve(res.result.data)
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    }).catch(err=>{
                        this.$message.error(err.result.message)
                        reject()
                    })
                })
            },
            //获取充电桩订单详情
            getOrderInfoById(datas) {
                return new Promise((resolve, reject) => {
                    this.$Axios._get({
                        url: this.$Config.apiUrl.getOrderInfoById,
                        params: {
                            orderId: datas.orderSn
                        },
                    }).then((res) => {
                        if(res.result.code == 0){
                            resolve(res.result.data)
                        }else{
                            this.$message.error(res.result.message)
                            reject()
                        }
                    }).catch(err=>{
                        this.$message.error(err.result.message)
                        reject()
                    })
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.complaint-content{
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    font-size: 14px;
    padding: 0 20px;
}
.complaint-title{
    padding: 20px 0
}
.step-box{
    // padding: 0 114px
}
.step-spot{
    width: 8px;
    height: 8px;
    background: #ADB0B3;
    border-radius: 50%
}
.step-spot1{
    width: 8px;
    height: 8px;
    background: #007AFF;
    border-radius: 50%
}
/deep/ .el-step__icon.is-text{
    border: none
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
/deep/ .supportInvoiceDate{
    padding: 0 !important;
    .date-range{
        margin: 0 !important;
    }
}
.footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    margin-bottom: 20px;
    padding-bottom: 20px
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 21px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 130px;
    margin-right: 16px;
}
.content{
    width: 267px
}
</style>