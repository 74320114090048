<template>
	<div class="content">
        <el-image 
            :style="{ width: width, height: height }"
            :src="url" 
            :preview-src-list="preSrcList.length?preSrcList:[url]">
        </el-image>
	</div>
</template>

<script>
	export default {
		data () {
			return {
                url: ''
			}
		},
		props:{
			imageUrl: {
				default: ''
			},
            extraParams: {
                default: ()=>{
                    return {}
                }
            },
            width: {
                default: '100px'
            },
            height: {
                default: '100px'
            },
            preSrcList: {
                default: ()=>{
                    return []
                }
            }
		},
        watch: {
            imageUrl: {
                deep: true,
                immediate: true,
                handler (val) {
                    this.getImageResource(val)
                }
            }
        },
		mounted() {
			
		},
		methods:{
            getImageResource (url) {
                this.$Axios._get({
                    url: this.$Config.apiUrl.queryWxImage,
                    method: "get",
                    params: {
                        url: url,
                        ...this.extraParams
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        this.url = 'data:image/jpeg;base64,' + res.result.data
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            }
		}
	}
</script>

<style lang="scss" scoped>
	
</style>